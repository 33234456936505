import './app.sass';

function App() {
  return (
    <div className="App">
      <div className="title">
        <span className="text">IT</span>
        <span className="char">.</span>
        <span className="text">GURUS</span>
        <span className="char">()</span>
      </div>
      <div className="dev-mode">
        Under Construction
      </div>
      {new Array(240).fill(0).map(fly => (<div className="firefly"></div>))}
    </div>
  );
}

export default App;
